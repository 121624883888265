<template>
    <div id="withd">
        <div class="d-flex p-3">
            <div class="me-auto">
                可提现金额: {{ money }} 元
            </div>
            <div>
                <a class="fw-bold text-hr" @click="whole">全部提现</a> 
            </div>
        </div>
        <div class="bg-white p-2 px-4">
            <div class="m-2">提现金额</div>
            <div class="m-2">
                <a>￥</a>
                 <input class="px-2 border-0 outline-input w-75" type="number" v-model.lazy="withdrawalMoney">
            </div>
        </div>
        <div class="bg-white p-2 px-4 my-2">
            <div class="m-2">提现方式</div>
            <div class="d-flex px-2 h4 fw-bold">
                <label class="form-check-label" for="watch">
                    微信钱包
                </label>
                <div class="ms-auto">
                    <input class="form-check-input" checked type="radio" name="watch" id="watch">
                </div>
            </div>
        </div>
        <div class="p-2 pt-0">
            <button class="btn btn-hr w-100" :disabled="!showBtn" @click.prevent="submit">提 现</button>
        </div>
        <div class="bg-white p-3">
            <h5>提现须知</h5>
            <p>1、如果提现失败请检查账户和微信是否实名, 是否为本人的真实信息;</p>
            <p>2、提现金额要大于10元</p>
            <p>3、提现金额仅计算到小数点后两位</p>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const showBtn = ref(false)
const money = ref(1000)
const withdrawalMoney = ref()
function whole() {
    withdrawalMoney.value = money.value
}
//数据监听
watch(() => withdrawalMoney.value, (a, o) => {
    if(a > o || o == undefined) {
        // money.value = money.value - a
        if(money.value < withdrawalMoney.value) {
            alert('对不起，金额不能大于账户余额')
            showBtn.value = false
            return
        }else{
            showBtn.value = true
        }
    }else{
        // money.value = money.value + (o - a)
        showBtn.value = true
    }  
})
//提交数据
function submit() {
    if(money.value < withdrawalMoney.value) {
        return alert('对不起，金额不能大于账户余额')
    }else{
        //准备数据
        const data = {type: 'watch', moneyn: withdrawalMoney.value}
        alert(data)
        money.value = money.value - withdrawalMoney.value
    }
}
</script>

<style>
#withd * {
    color: rgb(82, 82, 82);
}
#withd .outline-input {
    outline: none;
}
</style>